import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  VStack,
  Flex,
  Text,
  InputRightAddon,
  Accordion,
  StackDivider
} from "@chakra-ui/react";
import {SearchBar} from "./SearchBar"
import Sidebar from "./SideBar";
import SinglePost from "./SinglePost"



export const Posts = () => {
  const [posts, setPosts] = useState([]);

  const fetchPosts = async (query: any) => {
    const endpoint = query ? `/posts/search?q=${query}` : '/posts/all';
    let res = await fetch(endpoint);
    let data = await res.json();
    setPosts(data);
  };

  useEffect(() => {
    fetchPosts("");
  }, []);

  return (
    <VStack>
      <SearchBar onSearch={fetchPosts}></SearchBar> 
        <Accordion w="300px"  defaultIndex={[0]} allowMultiple allowToggle>
          {posts.map(p => (<SinglePost post={p}></SinglePost>))}
        </Accordion>
      </VStack>
  );
};

  export default Posts;

  