import React, { ReactElement, ReactNode } from "react";
import {
  Button,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  VStack,
  Flex,
  Text,
  InputRightAddon, 
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";



export const SinglePost = ({ post }: any) => {
    return (
        <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>{post.title}</Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      Category: {post.category}
      <br></br>
      {post.body}
      <br></br>
      Contact: {post.contactEmail}
      <br></br>
      {post.contactNumber}
      <br></br>
      Price: ${post.price}

    </AccordionPanel>
  </AccordionItem>
    );
  };
  
    export default SinglePost;



