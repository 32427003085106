import React, { createContext, useContext, useState, useEffect } from 'react';
import { Box,Flex, Link, VStack, Button} from '@chakra-ui/react';
interface sidebarProps {
  page: string;
  toPosts: () => void;
  toProfile: () => void;
  toMyPosts: () => void;
  toCreatePost: () => void;
}

function SideBar({page,toPosts,toProfile,toMyPosts,toCreatePost}:sidebarProps) {
  return (
    <Flex
      w="250px"
      h="100%"
      p="4"
      bgColor="gray.800"
      color="white"
      position="fixed"
      top="0"
      left="0"
    >
      <VStack>
        <Button onClick={toPosts}>
          Posts
        </Button>
        <Button onClick ={toMyPosts}>
          My Posts
        </Button>
        <Button onClick={toProfile}>
          Profile
        </Button>
        <Button onClick={toCreatePost}>
          Create Post
        </Button>
      </VStack>
    </Flex>
  );
};

export default SideBar;