import React, { ReactElement, ReactNode, useState, useEffect } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  InputRightAddon
} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

export const SearchBar = ({ onSearch }: any) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(inputValue);
    }, 200); // debounce

    return () => clearTimeout(timer);
  }, [inputValue, onSearch]);

  return (
    <HStack p = '0'>
        <Input size="sm" type="text" placeholder="Search..." border="1px solid #949494" onChange={(e) => setInputValue(e.target.value)}/>
          {/*<Button size="sm" borderLeftRadius={0} borderRightRadius={3.3} border="1px solid #949494">
            Search
          </Button>*/}
    </HStack>
  );
};
