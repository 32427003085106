import React, { createContext, useContext, useState, useEffect } from 'react';

import {
  ChakraProvider,
  Input,
  InputGroup,
  InputRightAddon,
  InputLeftElement,
  ThemeProvider,
  Box,
  Image,
  Center,
  Flex,
  Link,
  HStack,
  VStack,
  Button,
  Grid,
  theme,
  Container,
  Text
} from "@chakra-ui/react"
import {My_Posts} from "./My_Posts"
import SideBar from "./SideBar"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import source from "./logo.png"
import { SearchBar } from "./SearchBar";
import Posts from "./Posts"
import PostForm from './Form';


// Authentication store
const AuthContext = createContext({
  isLoggedIn: false,
  username: ''
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    (async () => {
      let req = await fetch('/me');
      let { signedIn, netId } = await req.json();
      setIsLoggedIn(signedIn);
      setUsername(netId);
    })();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, username }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

export const App = () => {
  console.log("Starting");
  return (

    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Content />
      </AuthProvider>
    </ChakraProvider>
  );
};

const Content = () => {
  const { isLoggedIn } = useAuth();
  console.log("Made it to Content");
  return isLoggedIn ? <Homepage /> : <GoogleLogin />;
};


const ImageHolder = () => {
  return (
  <Image mx = "690px" my = "100px" src={source}  borderRadius='full' boxSize='150px'/>
  )
}

const GoogleLogin = () => {
  return (
    <VStack my ='100px'> 
      <Image src={source}  borderRadius='full' boxSize='300px'/>
      <Link href = "/login/google">
        <Button border='2px' backgroundColor='#1e69a7' colorScheme='black' variant='link' size='lg'> Sign in </Button>
      </Link>
    </VStack>
  )
}



function Homepage() {
  const [page, setPage]= useState("Posts");

  const toPosts = () => {
  setPage("Posts");
  };

  const toMyPosts = () => {
    setPage("MyPosts");
  }

  const toProfile = () => {
    setPage("Profile");
  }

  const toCreatePost = () => {
    setPage("CreatePost");
  }

  console.log("Made it to Homepage");
  const { username } = useAuth();

  return (
    <HStack px="600px"> 
      <SideBar page={page} toPosts = {toPosts} toMyPosts = {toMyPosts} toProfile = {toProfile} toCreatePost = {toCreatePost}/>
        <VStack>
        {
                  (() => {
                      if(page == "Posts") {
                              return (
                                  <Posts/>
                              )
                          } else if (page == "Profile") {
                              return (
                              <p>Signed in as {username}</p>
                              )
                          } else if (page == "MyPosts") {
                              return (
                                  <My_Posts me={username}/>
                              )
                          } else if (page == 'CreatePost') {
                            return (
                                <PostForm></PostForm>
                              )
                          }
                  })()  
              }  
        </VStack>
    </HStack>
  )
}