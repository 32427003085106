import React, { useState } from 'react';
import { Text,Box, FormControl, FormLabel, Input, Textarea, Button,NumberInput,NumberInputField } from '@chakra-ui/react';

interface FormData {
  category: string;
  headerText: string;
  bodyText: string;
  price: number;
  contactNumber: number;
  contactEmail:string;
}

const PostForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    category: '',
    headerText: '',
    bodyText: '',
    price: 10.00,
    contactNumber:0,
    contactEmail: '',

  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [statusText, setStatusText] = useState<string>('Unsubmitted'); // State to hold the status text

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    let req = await fetch('/posts/create', {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify({
          category: formData.category,
          title: formData.headerText,
          body:   formData.bodyText,
          price: formData.price,
          contactNumber: formData.contactNumber,
          contactEmail: formData.contactEmail,
        })
      });
    req.status == 200 ? console.log("Success") : console.log("Fail");
    const responseText = await req.text();
    setStatusText(responseText);
  };

  return (
    <Box maxW="400px" m="auto" mt={8}>
      <form onSubmit={handleSubmit}>

      <FormControl isRequired mb={4}>
          <FormLabel>Category</FormLabel>
          <Input
            type="text"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            placeholder="Enter Category Text"
          />
        </FormControl>

        <FormControl isRequired mb={4}>
          <FormLabel>Header Text</FormLabel>
          <Input
            type="text"
            name="headerText"
            value={formData.headerText}
            onChange={handleInputChange}
            placeholder="Enter Header Text"
          />
        </FormControl>

        <FormControl isRequired mb={4}>
          <FormLabel>Body Text</FormLabel>
          <Textarea
            name="bodyText"
            value={formData.bodyText}
            onChange={handleInputChange}
            placeholder="Enter Body Text"
          />
        </FormControl>

        <FormControl isRequired mb={4}>
          <FormLabel>Price</FormLabel>
          <NumberInput
            value={formData.price}
            onChange={(valueString, valueNumber) => setFormData((prevData) => ({ ...prevData, price: valueNumber }))}
            precision={2}
            step={0.01}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <FormControl  mb={4}>
          <FormLabel>Contact Email</FormLabel>
          <Textarea
            name="contactEmail"
            value={formData.contactEmail}
            onChange={handleInputChange}
            placeholder="Enter Contact Email"
          />
        </FormControl>

        <FormControl  mb={4}>
          <FormLabel>Contact Number</FormLabel>
          <NumberInput
            value={formData.contactNumber}
            onChange={(valueString, valueNumber) => setFormData((prevData) => ({ ...prevData, contactNumber: valueNumber }))}
            precision={2}
            step={0.01}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>

        <Button type="submit" colorScheme="blue">
          Submit
        </Button>
      </form>
      <Text>{statusText}</Text>
    </Box>
  );
};

export default PostForm;