import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  Flex,
  Text,
  InputRightAddon,
  Accordion,
} from "@chakra-ui/react";
import SinglePost from "./SinglePost"


export const My_Posts = ({ me }: any) => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
      (async () => {
        let req = await fetch('/posts/search?by=' + me);
        let res = await req.json();
        setPosts(res);
      })();
    }, []);

    return (
    <Flex>
      <Accordion w="300px"  defaultIndex={[0]} allowMultiple allowToggle>
          {posts.map(p => (<SinglePost post={p}></SinglePost>))}
      </Accordion>
    </Flex>
    );
  };